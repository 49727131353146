import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

import { SavedSearchStoreService } from '@saved-search/store/services/saved-search-store.service';

@Component({
    selector: 'saved-search-list',
    templateUrl: './saved-search-list.component.html',
    styleUrls: ['./saved-search-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavedSearchListComponent {
    @Output() public createSavedSearch = new EventEmitter<void>();
    @Output() public closed = new EventEmitter<void>();

    public readonly activeSavedSearchId$ = this.savedSearchStoreService.activeSavedSearchId$;
    public readonly savedSearchesInfo$ = this.savedSearchStoreService.savedSearchesInfo$;

    constructor(private readonly savedSearchStoreService: SavedSearchStoreService) { }

    public onSavedSearchCreate(): void {
        this.savedSearchStoreService.showSaveYourSearchDialog();

        this.onClose();
    }

    public onSavedSearchSelected(activeSavedSearchId: number): void {
        this.savedSearchStoreService.runSavedSearch(activeSavedSearchId);

        this.onClose();
    }

    public onEditSavedSearch(activeSavedSearchId: number): void {
        this.savedSearchStoreService.showAddEditSavedSearchDialog(activeSavedSearchId);
    }

    public onCreateNewSavedSearch(): void {
        this.createSavedSearch.emit();
    }

    public onClose(): void {
        this.closed.emit();
    }
}
