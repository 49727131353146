<ng-container *ngLet="canSearchForListings$ | async as canSearchForListings">

    <div class="mobile-bottombar" [class.mobile-bottombar--full]="canSearchForListings">

        <rpc-icon-button class="mobile-bottombar-btn" [iconName]="'home'"
            [color]="activeItem === 'landing' ? 'accent': null" (clicked)="onHomeClicked()">
        </rpc-icon-button>

        <rpc-icon-button class="mobile-bottombar-btn" *ngIf="canSearchForListings" [iconName]="'search'"
            [color]="activeItem === 'search' ? 'accent': null" (clicked)="onSearchClicked()">
        </rpc-icon-button>

        <rpc-icon-button class="mobile-bottombar-btn" [iconName]="'folder'"
            [color]="activeItem === 'portfolio' ? 'accent': null" (clicked)="onPortfolioClicked()">
        </rpc-icon-button>

        <rpc-icon-button class="mobile-bottombar-btn" [iconName]="'appointment'"
            [color]="activeItem === 'appointments' ? 'accent': null" (clicked)="onAppointmentsClicked()">
        </rpc-icon-button>

        <!-- TODO Dan: add proper class for badge -->
        <rpc-icon-button class="mobile-bottombar-btn" [iconName]="'bell'"
            [color]="activeItem === 'notifications' ? 'accent': null"
            [badgeCountAlert]="unviewedNotificationTotalCount$ | async" [badgeCountAlertLimit]="badgeCountAlertLimit"
            (clicked)="onNotificationBellClicked()">
        </rpc-icon-button>

    </div>

</ng-container>