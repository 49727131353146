<div class="rpc-saved-search-list" *ngLet="{
        savedSearchesInfo: savedSearchesInfo$ | async,
        activeSavedSearchId: activeSavedSearchId$ | async
        } as savedSearchesData">

    <div class="rpc-saved-search-list-header">

        <div class="rpc-saved-search-list-header-title-wrap">

            <div class="rpc-saved-search-list-header-title">
                {{'SAVED_SEARCH_LIST.TITLES.SAVED_SEARCHES' | translate}}
            </div>

            <rpc-icon-button class="rpc-saved-search-list-header-close" [iconName]="'cross'" (clicked)="onClose()"
                [title]="'SAVED_SEARCH_LIST.TITLES.CLOSE'">
            </rpc-icon-button>

        </div>

        <!-- TODO: Iaroslav Add disabled if search criteria is empty in scope of RPC-3244 -->
        <div class="rpc-saved-search-list-add" (click)="onSavedSearchCreate()">

            <rpc-icon class="rpc-saved-search-list-add-icon" [iconName]="'saved-search'">
            </rpc-icon>

            <div class="rpc-saved-search-list-add-text">
                {{ 'SAVED_SEARCH_LIST.TITLES.SAVE_YOUR_SEARCH' | translate }}
            </div>

            <rpc-icon class="rpc-saved-search-list-add-trigger" [iconName]="'arrow-right'">
            </rpc-icon>

        </div>

    </div>

    <div class="rpc-saved-search-list-body">

        <ng-container *ngFor="let savedSearchInfo of savedSearchesData.savedSearchesInfo; let index = index;">

            <saved-search-info class="rpc-saved-search-list-item" [savedSearch]="savedSearchInfo"
                [isEditable]="savedSearchInfo.isEditable"
                [isActive]="savedSearchInfo.id === savedSearchesData.activeSavedSearchId"
                (clicked)="onSavedSearchSelected(savedSearchInfo.id)" (edit)="onEditSavedSearch(savedSearchInfo.id)">
            </saved-search-info>

            <mat-divider class="rpc-saved-search-list-divider"
                *ngIf="index < (savedSearchesData.savedSearchesInfo.length - 1)">
            </mat-divider>

        </ng-container>

    </div>

</div>