import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AppConfigurationService } from '@app-config/services/app-configuration.service';
import { CustomerRegistationFormData } from '@auth/models/customer-registration-data';
import { Invitation } from '@auth/models/invitation';
import { RegistrationData } from '@auth/models/registration-data';
import { RegistrationStoreService } from '@auth/store/services/registration-store.service';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { fadeInScaleAnimation } from '@core-animations/element-animations';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { ProgressBarService } from '@core-utils/progress-bar/progress-bar.service';
import { ProfileErrorsService } from '@profile/services/profile-errors.service';
import { ProfileStoreService } from '@profile/store/services/profile-store.service';

@Component({
    selector: 'registration',
    templateUrl: './registration.component.html',
    styleUrls: ['../../auth-base/auth-base.component.scss', './registration.component.scss'],
    animations: [fadeInScaleAnimation]
})
export class RegistrationComponent implements OnInit, OnDestroy {

    public readonly isAuthorized$ = this.userStoreService.getIsAuthorized();
    public readonly companyConfiguration$ = this.configurationService.configuration$.pipe(map(configuration => configuration.company));
    public readonly companyLogo$ = this.configurationService.getCompanyLogo();

    public registerForm: FormGroup;
    public invitation: Invitation;
    private readonly unsubscribe$ = new Subject<void>();

    constructor(
        private readonly configurationService: AppConfigurationService,
        private readonly routeService: RouteService,
        private readonly progressBarService: ProgressBarService,
        private readonly userStoreService: UserStoreService,
        private readonly registrationStoreService: RegistrationStoreService,
        private readonly profileStoreService: ProfileStoreService,
        private readonly profileErrorsService: ProfileErrorsService
    ) {
        configurationService.configuration = { layout: { spinner: { hidden: true } } };
    }

    public ngOnInit(): void {
        this.profileStoreService.getProfileError()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((error) => this.profileErrorsService.showError(error));

        this.subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public cancel(): void {
        this.routeService.navigate(RpcRoute.FindHome).catch(() => { });
    }

    public continue(): void {
        this.userStoreService.logout(false, false, false);
    }

    public onRegistration(customer: CustomerRegistationFormData): void {
        this.progressBarService.show();

        const registrationData: RegistrationData = new RegistrationData();

        registrationData.agentId = this.invitation.agent.id;
        registrationData.customerId = customer.id;
        registrationData.firstName = customer.firstName;
        registrationData.lastName = customer.lastName;
        registrationData.password = customer.password;
        registrationData.phoneId = this.invitation.customer.phoneNumberInfo?.phoneId;
        registrationData.phoneNumber = customer.phoneNumberInfo?.phoneNumber ?? '';
        registrationData.countryCode = customer.phoneNumberInfo?.countryCode ?? '';
        registrationData.accountName = customer.email;
        registrationData.agentEmail = this.invitation.agent.email;
        registrationData.agentName = this.invitation.agent.fullName;
        registrationData.primaryCustomerId = this.invitation.primaryCustomerId;
        registrationData.companyId = (this.invitation.invitatingAgent ?? this.invitation.agent).companyId;

        if (this.invitation.invitatingAgent != null) {
            registrationData.invitatingAgentId = this.invitation.invitatingAgent.id;
        }

        this.registrationStoreService.register(registrationData);
    }

    private subscribe(): void {
        this.userStoreService
            .getUserInvitation()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(invitation => this.invitation = invitation);

        this.userStoreService
            .selectLoginProcessData$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
                if (data != null) {
                    this.routeService.navigate(RpcRoute.AgentSelection).finally(() => this.progressBarService.hide());
                }
            });
    }
}