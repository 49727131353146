import { createAction, props } from '@ngrx/store';

import { ApiError } from '@error/models/api-error';
import { SavedSearch } from '@saved-search/models/saved-search';
import { SavedSearchCreationRequest } from '@saved-search/models/saved-search-creation-request';
import { SavedSearchModification } from '@saved-search/models/saved-search-modification';

export const loadSavedSearches = createAction('[Saved Search] Load Saved Searches');

export const loadSavedSearchesSuccess = createAction(
    '[Saved Search] Load Saved Searches Success',
    props<{ savedSearches: SavedSearch[] }>()
);
export const loadSavedSearchesFailed = createAction(
    '[Saved Search] Load Saved Searches Failed',
    props<{ error: ApiError }>()
);

export const createSavedSearchRequested = createAction(
    '[Saved Search] Create Saved Search Requested',
    props<{ savedSearchModification: SavedSearchModification }>()
);
export const createSavedSearch = createAction(
    '[Saved Search] Create Saved Search',
    props<{ savedSearch: SavedSearchCreationRequest }>()
);
export const createSavedSearchSuccess = createAction(
    '[Saved Search] Create Saved Search Success',
    props<{ tempId: number, savedSearchId: number, savedSearchName: string, folderId?: number }>()
);
export const createSavedSearchFailed = createAction(
    '[Saved Search] Create Saved Search Failed',
    props<{ error: ApiError, savedSearch: SavedSearchCreationRequest }>()
);

export const updateSavedSearchRequested = createAction(
    '[Saved Search] Update Saved Search Requested',
    props<{ savedSearchModification: SavedSearchModification }>()
);
export const updateSavedSearch = createAction(
    '[Saved Search] Update Saved Search',
    props<{ previousSavedSearch: SavedSearchCreationRequest, currentSavedSearch: SavedSearchCreationRequest, newMatchesIds: number[], updateCriteria: boolean }>()
);
export const updateSavedSearchSuccess = createAction(
    '[Saved Search] Update Saved Search Success',
    props<{ savedSearch: SavedSearchCreationRequest, folderId?: number, includePreviousMatches: boolean, newMatchesIds: number[] }>()
);
export const updateSavedSearchFailed = createAction(
    '[Saved Search] Update Saved Search Failed',
    props<{ error: ApiError, savedSearch: SavedSearchCreationRequest, folderId?: number, includePreviousMatches: boolean, newMatchesIds: number[] }>()
);

export const deleteSavedSearch = createAction('[Saved Search] Delete Saved Search', props<{ savedSearch: SavedSearch }>());

export const deleteSavedSearchSuccess = createAction('[Saved Search] Delete Saved Search Success', props<{ savedSearch: SavedSearch }>());

export const deleteSavedSearchFailed = createAction(
    '[Saved Search] Delete Saved Search Failed',
    props<{ error: ApiError, savedSearch: SavedSearch, removeActiveSavedSearchId: boolean }>()
);

export const setActiveSavedSearchId = createAction('[Saved Search] Set Active Saved Search Id', props<{ activeSavedSearchId: number }>());
export const activateSavedSearch = createAction(
    '[Saved Search] Activate Saved Search',
    props<{ activeSavedSearchId: number, shouldSetLoading: boolean }>()
);

export const addCustomerSavedSearchActivity = createAction(
    '[Saved Search] Add Customer Saved Search Activity',
    props<{ savedSearchId: number, isNew: boolean, categoryId: number }>()
);

export const showDeleteSavedSearchDialog = createAction('[Saved Search] Show Delete Saved Search Dialog', props<{ savedSearch: SavedSearch, actionOnSuccess: () => void }>());

export const runSavedSearch = createAction('[Saved Search] Run Saved Search', props<{ savedSearchId: number }>());
export const showAddEditSavedSearchDialog = createAction('[Saved Search] Show Add Edit Saved Search Dialog', props<{ savedSearchId?: number }>());
export const showSavedSearchListDialog = createAction('[Saved Search] Show Saved Search List Dialog');
export const showSaveYourSearchDialog = createAction('[Saved Search] Show Save Your Search Dialog');
