import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, Subject, takeUntil } from 'rxjs';

import { DateConstants } from '@core-constants/dates/date.constants';
import { RemoveFolderDialogComponent } from '@folders/components/remove-folder-dialog/remove-folder-dialog.component';
import { FolderDetails } from '@folders/models/folder-details';
import { IRemoveFolderModel } from '@folders/models/remove-folder-model';
import { FoldersStoreReadService } from '@folders/store/services/folders-store-read.service';
import { FoldersStoreWriteService } from '@folders/store/services/folders-store-write.service';
import { DEFAULT_REMOVE_FOLDER_DIALOG_CONFIG } from '../remove-folder-dialog/constants/remove-folder-dialog.constants';
import { SYSTEM_FOLDERS_INFO } from '@folders/constants/folder.constants';
import { GoogleAnalyticsStoreService } from '@core-layout/app/store/services/google-analytics-store.service';
import { GoogleAnalyticsEventName } from 'app/modules/core-modules/enums/google-analytics-event-name';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
    selector: 'folder-details',
    templateUrl: './folder-details.component.html',
    styleUrls: ['./folder-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderDetailsComponent implements OnInit, OnDestroy {
    @Input() public folder: FolderDetails | null;
    @Input() public showFolderName: boolean;
    @Input() public hasClose: boolean;
    @Input() public isDefault: boolean;
    @Output() public closed = new EventEmitter<void>();

    private readonly unsubscribe$ = new Subject<void>();
    private hasListings: boolean;

    public readonly DateConstants = DateConstants;
    public savedSearchesNames: string[] = [];
    public hasMultipleSavedSearches = false;
    public isSavedSearchesAreaExpanded = false;

    constructor(
        private readonly foldersStoreWriteService: FoldersStoreWriteService,
        private readonly foldersStoreReadService: FoldersStoreReadService,
        private readonly matDialog: MatDialog,
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
        @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: { folder: FolderDetails; isDefault: boolean; showFolderName: boolean; hasClose: boolean },
    ) { }

    public ngOnInit(): void {
        this.folder = this.folder ?? this.bottomSheetData?.folder;
        this.isDefault = this.isDefault ?? this.bottomSheetData?.isDefault;
        this.showFolderName = this.showFolderName ?? this.bottomSheetData?.showFolderName;
        this.hasClose = this.hasClose ?? this.bottomSheetData?.hasClose;

        this.foldersStoreReadService.getHasListingsByFolderId(this.folder.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((hasListings) => {
                this.hasListings = hasListings;
            });

        this.foldersStoreReadService.getSavedSearchesNamesAttachedToFolder(this.folder.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(savedSearchesNames => {
                this.savedSearchesNames = savedSearchesNames;
                this.hasMultipleSavedSearches = savedSearchesNames.length > 1;
            });
    }

    public onDefaultFolderChange(isSetAsDefault: boolean): void {
        if (this.folder.id === SYSTEM_FOLDERS_INFO.ALL_HOMES.id && !isSetAsDefault) {
            this.googleAnalyticsStoreService.addEvent(GoogleAnalyticsEventName.Folder_RemoveDefault_AddedToPortfolio);
        } else if (this.folder.id > 0 && isSetAsDefault) {
            this.googleAnalyticsStoreService.addEvent(GoogleAnalyticsEventName.Folder_SetDefault_Custom);
        }

        this.foldersStoreWriteService.setDefaultFolder(isSetAsDefault ? this.folder.id : null);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public edit(): void {
        this.foldersStoreWriteService.showAddEditFolderDialog({ id: this.folder.id, name: this.folder.name });

        this.closed.emit();
    }

    public onDeleteFolder(): void {
        const data = {
            id: this.folder.id,
            name: this.folder.name,
            hasListings: this.hasListings
        };

        this.matDialog.open(RemoveFolderDialogComponent, { ...DEFAULT_REMOVE_FOLDER_DIALOG_CONFIG, data })
            .afterClosed()
            .pipe(
                filter(model => model != null),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((removeFolderModel: IRemoveFolderModel) => {
                this.foldersStoreWriteService.remove(removeFolderModel);
            });

        this.closed.emit();
    }

    public onSavedSearchesCliked(): void {
        if (this.hasMultipleSavedSearches) {
            this.isSavedSearchesAreaExpanded = !this.isSavedSearchesAreaExpanded;
        }
    }

    public onClose(): void {
        this.closed.emit();
    }
}
