<div class="restore-password-page-wrap">

    <not-authorized-toolbar class="restore-password-page-toolbar">
    </not-authorized-toolbar>

    <div class="restore-password-page-body">

        <div class="auth-wrap restore-password" @fadeIn>
            <svg class="auth-decoration" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 443 487">
                <g>
                    <linearGradient id="gradientTop" gradientUnits="userSpaceOnUse" x1="221.5522" y1="486.4993"
                        x2="221.5522" y2="-9.094947e-13">
                        <stop class="bg-top-start-color" offset="0" />
                        <stop class="bg-top-stop-color" offset="1" />
                    </linearGradient>
                    <linearGradient id="gradientBottom" gradientUnits="userSpaceOnUse" x1="-129.3398" y1="638.4081"
                        x2="-129.3398" y2="639.4081"
                        gradientTransform="matrix(303.0215 0 0 -446.5007 39484.3359 285536.4375)">
                        <stop class="bg-bottom-start-color" offset="0" />
                        <stop class="bg-bottom-stop-color" offset="1" />
                    </linearGradient>
                    <path class="bg-top" d="M276.9,2c0,63.1-48.4,112-88.6,131.1s-79.4,46-86.6,160.7C95,400.2,12,476,0,486.5h443.1V0H277
                        C276.9,0.7,276.9,1.3,276.9,2z" />
                    <path class="bg-bottom" d="M140.1,486.7c0,0,73.7-14.4,91.9-102.8c23.1-112,159.3-37.1,155.8-186.8c-3-128.8,55.3-156.8,55.3-156.8v446.5
                        L140.1,486.7z" />
                </g>
            </svg>
            <div class="auth-inner" style="--bg-image: url('/assets/images/decoration/forgot-password.png');">
                <div class="auth-form-wrap">
                    <div class="auth-title">
                        {{ 'RESTORE_PASSWORD.TITLE' | translate }}
                    </div>
                    <div class="auth-form">
                        <div class="auth-description">
                            <div class="auth-description-item">
                                {{ 'RESTORE_PASSWORD.RESTORE_EXPLANATION' | translate }}
                            </div>
                        </div>
                        <form name="restorePasswordForm" [formGroup]="restorePasswordForm" novalidate>
                            <rpc-input class="auth-form-input" type="password" appearance="fill"
                                autocomplete="current-password" label="RESTORE_PASSWORD.FIELDS.PASSWORD"
                                [options]="passwordControlOptions">
                            </rpc-input>
                            <rpc-input class="auth-form-input" type="password" appearance="fill"
                                autocomplete="current-password" label="RESTORE_PASSWORD.FIELDS.PASSWORD_CONFIPMATION"
                                [options]="passwordConfirmControlOptions">
                            </rpc-input>
                            <rpc-button class="auth-submit-button" [mainButton]="true" [color]="'accent'"
                                [disabled]="restorePasswordForm.invalid" [appearanceType]="'flat'"
                                title="RESTORE_PASSWORD.UPDATE_BUTTON" (clicked)="restorePassword()">
                            </rpc-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

<additional-footer class="global-additional-footer">
</additional-footer>