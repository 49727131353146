import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SavedSearch } from '@saved-search/models/saved-search';
import { SavedSearchModification } from '../../models/saved-search-modification';
import * as savedSearchActions from '../actions/saved-search.actions';
import * as savedSearchSelectors from '../selectors/saved-search.selectors';

@Injectable({ providedIn: 'root' })
export class SavedSearchStoreService {
    constructor(private readonly store: Store) { }

    public readonly isZeroSearchState$ = this.store.select(savedSearchSelectors.selectIsZeroSearchState);
    public readonly savedSearches$ = this.store.select(savedSearchSelectors.selectSavedSearches);
    public readonly activeSavedSearchId$ = this.store.select(savedSearchSelectors.selectActiveSavedSearchId);
    public readonly activeSavedSearch$ = this.store.select(savedSearchSelectors.selectActiveSavedSearch);
    public readonly activeSavedSearchFolderId$ = this.store.select(savedSearchSelectors.selectActiveSavedSearchFolderId);
    public readonly savedSearchesInfo$ = this.store.select(savedSearchSelectors.selectSavedSearchesInfo);
    public readonly savedSearchesToModify$ = this.store.select(savedSearchSelectors.selectSavedSearchesToModify);

    public getSavedSearch(id: number): Observable<SavedSearch> {
        return this.store.select(savedSearchSelectors.selectSavedSearch(id));
    }

    public loadSavedSearches(): void {
        this.store.dispatch(savedSearchActions.loadSavedSearches());
    }

    public updateSavedSearch(savedSearchModification: SavedSearchModification): void {
        this.store.dispatch(savedSearchActions.updateSavedSearchRequested({ savedSearchModification }));
    }

    public activateSavedSearch(activeSavedSearchId: number, shouldSetLoading = true): void {
        this.store.dispatch(savedSearchActions.activateSavedSearch({ activeSavedSearchId, shouldSetLoading }));
    }

    public showDeleteSavedSearchDialog(savedSearch: SavedSearch, actionOnSuccess: () => void): void {
        this.store.dispatch(savedSearchActions.showDeleteSavedSearchDialog({ savedSearch, actionOnSuccess }));
    }

    public runSavedSearch(savedSearchId: number): void {
        this.store.dispatch(savedSearchActions.runSavedSearch({ savedSearchId }));
    }

    public showAddEditSavedSearchDialog(savedSearchId?: number): void {
        this.store.dispatch(savedSearchActions.showAddEditSavedSearchDialog({ savedSearchId }));
    }

    public showSavedSearchListDialog(): void {
        this.store.dispatch(savedSearchActions.showSavedSearchListDialog());
    }

    public showSaveYourSearchDialog(): void {
        this.store.dispatch(savedSearchActions.showSaveYourSearchDialog());
    }
}