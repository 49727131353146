import { Component, EventEmitter, Output } from '@angular/core';

import { SavedSearchInfo } from '@saved-search/models/saved-search-info';
import { SavedSearchStoreService } from '@saved-search/store/services/saved-search-store.service';

@Component({
    selector: 'save-your-search',
    templateUrl: './save-your-search.component.html',
    styleUrls: ['./save-your-search.component.scss']
})
export class SaveYourSearchComponent {
    @Output() public closed = new EventEmitter<void>();

    public readonly savedSearchesToModify$ = this.savedSearchStoreService.savedSearchesToModify$;

    constructor(
        private readonly savedSearchStoreService: SavedSearchStoreService,
    ) { }

    public onSavedSearchCreate(): void {
        this.savedSearchStoreService.showAddEditSavedSearchDialog();

        this.onClose();
    }

    public onSavedSearchSelected(savedSearch: SavedSearchInfo): void {
        const { id, name, searchNewMatches, folderId, } = savedSearch;

        this.savedSearchStoreService.updateSavedSearch({ id, name, searchNewMatches, folderId, updateCriteria: true });

        this.onClose();
    }

    public onClose(): void {
        this.closed.emit();
    }
}
