import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';
import { Observable, Subject, distinctUntilChanged, map, startWith } from 'rxjs';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';

import { ScrollConstants } from '@core-layout/scroll-to-top/scroll.constants';

@Injectable({ providedIn: 'root' })
export class ScrollService implements OnDestroy {

    public scrollToTopVisibility$ = new Subject<boolean>();
    public scrollPosition$ = new Observable<number>();
    public isStickyToolbar$ = new Observable<boolean>();

    private readonly minimizedSecondaryToolbarScrollLengthInPixels = 5;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly pageScrollService: PageScrollService,
        private readonly scrollDispatcher: ScrollDispatcher,
    ) { }

    public ngOnDestroy(): void {
        this.scrollToTopVisibility$.complete();
    }

    public registerScrollHandler(): void {
        const scrollPosition$ = this.scrollDispatcher.scrolled().pipe(
            map((event: CdkScrollable) => event != null ? event.getElementRef().nativeElement.scrollTop : window.scrollY)
        );

        this.scrollPosition$ = scrollPosition$;

        this.isStickyToolbar$ = scrollPosition$.pipe(
            map(scrollPosition => scrollPosition <= this.minimizedSecondaryToolbarScrollLengthInPixels),
            startWith(true),
            distinctUntilChanged(),
        );
    }

    public scroll(scrollTarget: string, scrollFinishListener: EventEmitter<boolean> = null, duration = 0, scrollToBottom = false): void {
        const scrollContainers = this.getScrollableContainers();

        if (scrollContainers.length > 0) {
            const offset = scrollToBottom ? -Math.abs(scrollContainers[0].clientHeight) : 0;

            setTimeout(() => {
                return this.pageScrollService.scroll({
                    document: this.document,
                    scrollViews: this.getScrollableContainers(),
                    scrollTarget,
                    scrollFinishListener,
                    duration,
                    scrollOffset: offset
                });
            });
        }
    }

    public scrollToTop(): void {
        this.scroll(`.${ScrollConstants.ScrollToTopClasses.Target}`);
    }

    public scrollToElementById(elementId: string, behavior: 'auto' | 'smooth' = 'auto'): void {
        const element = document.getElementById(elementId);

        if (element != null) {
            setTimeout(() => element.scrollIntoView({ behavior }));
        }
    }

    public scrollInContainer(elementSelector: string, options: ScrollToOptions = { top: 0, left: 0, behavior: 'auto' }): void {
        const element = document.querySelector(elementSelector);

        if (element != null) {
            setTimeout(() => element.scroll(options));
        }
    }

    public isElementScrollable(elementSelector: string): boolean {
        const element = document.querySelector(elementSelector);

        return element == null
            ? false
            : element.scrollHeight > element.clientHeight;
    }

    private getScrollableContainers(): HTMLElement[] {
        const scrollContainers = this.document.getElementsByClassName(ScrollConstants.ScrollToTopClasses.Container);

        return Array.prototype.map.call(scrollContainers, (container: HTMLElement) => container) as HTMLElement[];
    }
}