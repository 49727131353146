import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { RouteService } from '@core-layout/app/services/route.service';

@Component({
    selector: 'profile-toolbar',
    templateUrl: './profile-toolbar.component.html',
    styleUrls: ['./profile-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileToolbarComponent {
    @Input() public isAdditionalProfile: boolean;

    @Output() public edit = new EventEmitter<void>();
    @Output() public navigateBack = new EventEmitter<void>();

    constructor(private readonly routeService: RouteService,) { }

    public onNavigateBack(): void {
        this.navigateBack.emit();

        // const route = this.isAdditionalProfile ?  RpcRoute.Profile : RpcRoute.Profile;

        // this.routeService.navigate(RpcRoute.Profile).catch(() => { });
    }

    public onEdit(): void {
        this.edit.emit();
    }

}
