<mat-toolbar class="toolbar" *ngLet="isUserAuthorized$ | async as isUserAuthorized">

    <div class="toolbar-box" [class.not-authorized]="!isUserAuthorized">

        <ng-container *ngIf="isUserAuthorized">

            <!-- Mobile Menu -->
            <div class="toolbar-m-menu">

                <rpc-icon-button class="toolbar-m-menu-item" [title]="'TOOLBAR.TITLES.SHOW_MENU'" [iconName]="'burger'"
                    (clicked)="toggleSidebarOpen('mobileMenu')">
                </rpc-icon-button>

            </div>

            <mat-divider [vertical]="true" class="divider-first">
            </mat-divider>

        </ng-container>

        <logo class="toolbar-logo">
        </logo>

        <div class="toolbar-auth-wrap" *ngIf="!isUserAuthorized && !(hideLoginRedirect$ | async)">

            <rpc-button [link]="RpcRoute.Login" [title]="'Login'" [color]="'accent'">
            </rpc-button>

        </div>

        <ng-container *ngIf="isUserAuthorized">

            <!-- Desktop Menu -->
            <ng-container *ngIf="(isSettingsLoading$ | async) === false">

                <div class="toolbar-menu" *ngLet="unviewedListingsCount$ | async as unviewedListingsCount">

                    <ng-container *ngIf="canSearchForListings$ | async">

                        <rpc-button class="toolbar-menu-item" [title]="'TOOLBAR.TITLES.FIND_HOME'"
                            [actionClass]="'active'" [actionTrigger]="isFindHomeActive$ | async"
                            (clicked)="onFindHomeClick()">
                        </rpc-button>

                        <ng-container
                            *ngLet="{savedSearchesInfo: savedSearchesInfo$ | async, activeSavedSearchId:activeSavedSearchId$ | async} as savedSearchesData">

                            <rpc-menu-button class="toolbar-menu-item" #savedSearchesMenuButton
                                [title]="'TOOLBAR.TITLES.SAVED_SEARCHES'"
                                [menuActionClass]="'saved-search-info-dropdown'"
                                [disabled]="savedSearchesData.savedSearchesInfo.length === 0">

                                <ng-container
                                    *ngFor="let savedSearchInfo of savedSearchesData.savedSearchesInfo; let index = index;">

                                    <saved-search-info class="saved-search-info-item" [savedSearch]="savedSearchInfo"
                                        [isEditable]="savedSearchInfo.isEditable"
                                        [isActive]="savedSearchInfo.id === savedSearchesData.activeSavedSearchId"
                                        (clicked)="onSavedSearchSelected(savedSearchInfo.id)"
                                        (edit)="onEditSavedSearch(savedSearchInfo.id)">
                                    </saved-search-info>

                                    <mat-divider class="saved-search-info-divider"
                                        *ngIf="index < (savedSearchesData.savedSearchesInfo.length - 1)">
                                    </mat-divider>

                                </ng-container>

                            </rpc-menu-button>

                        </ng-container>

                    </ng-container>

                    <rpc-button class="toolbar-menu-item" [link]="portfolioRouterLink$ | async"
                        [routerLinkActive]="'active'" [title]="'TOOLBAR.TITLES.MY_LISTINGS'"
                        [badgeCountAlert]="unviewedListingsCount" [alt]="unviewedListingsCount > 0 
                            ? ('TOOLBAR.TOOLTIPS.NEW_LISTINGS_IN_PORTFOLIO'  | translate : {count: unviewedListingsCount}) 
                            : 'TOOLBAR.TITLES.MY_LISTINGS'">
                    </rpc-button>

                    <rpc-button
                        *ngLet="{unviewedAppointmentsCount: unviewedAppointmentsCount$ | async, appointmentsCount: appointmentsCount$ | async } as appointmentCounts"
                        class="toolbar-menu-item" [link]="RpcRoute.Appointments" [routerLinkActive]="'active'"
                        [title]="'TOOLBAR.TITLES.MY_APPOINTMENTS'" [badgeCount]="appointmentCounts.appointmentsCount"
                        [badgeCountAlert]="appointmentCounts.unviewedAppointmentsCount" [alt]="appointmentCounts.unviewedAppointmentsCount > 0 
                        ? ('TOOLBAR.TOOLTIPS.NEW_UPCOMING_APPOINTMENTS_IN_PORTFOLIO'  | translate : {count: (appointmentCounts.unviewedAppointmentsCount)})
                        : appointmentCounts.appointmentsCount > 0 
                            ? ('TOOLBAR.TOOLTIPS.APPOINTMENTS_IN_PORTFOLIO'  | translate : {count: appointmentCounts.appointmentsCount})
                            : 'TOOLBAR.TITLES.MY_APPOINTMENTS'">
                    </rpc-button>

                </div>

            </ng-container>


            <div class="toolbar-right-section">

                <rpc-icon-button class="toolbar-knowledge" [actionClass]="'active'"
                    [actionTrigger]="isKnowledgeBaseOpened$ | async" [iconName]="'question'"
                    [title]="'TOOLBAR.TITLES.KNOWLEDGE_BASE'" (clicked)="onKnowledgeBaseClicked()">
                </rpc-icon-button>

                <ng-container *ngLet="agents$ | async as agents">

                    <div class="toolbar-agent" *ngIf="agents.length > 0">

                        <div class="toolbar-agent-btn" [title]="'TOOLBAR.TITLES.CONTACT_AGENT' | translate"
                            [matMenuTriggerFor]="agentsList" matRipple>

                            <avatar-initials *ngIf="agents.length === 1; else multipleAgents"
                                class="toolbar-agent-btn-avatar" mat-icon-button [userFullName]="agents[0].fullName"
                                [userProfileImageUrl]="agents[0].profilePictureUrl">
                            </avatar-initials>

                            <ng-template #multipleAgents>

                                <avatars-icon class="toolbar-agent-btn-avatar" mat-icon-button
                                    [front]="getAgentAvatarInfo(agents[0])" [back]="getAgentAvatarInfo(agents[1])">
                                </avatars-icon>

                            </ng-template>

                            <div class="toolbar-agent-btn-text">{{'TOOLBAR.TITLES.CONTACT_AGENT' | translate}}</div>

                        </div>

                        <mat-menu #agentsList="matMenu" class="toolbar-agent-modal" [overlapTrigger]="false">

                            <portfolio-agents-list class="toolbar-agent-info" [agents]="agents">
                            </portfolio-agents-list>

                        </mat-menu>

                    </div>

                </ng-container>

                <div class="toolbar-notification"
                    *ngLet="unviewedNotificationTotalCount$ | async as unviewedNotificationTotalCount">

                    <rpc-menu-icon-button class="toolbar-notification-item d-view"
                        #rpcMenuIconButtonApi="rpcMenuIconButtonApi" [alt]="'TOOLBAR.TITLES.SHOW_NOTIFICATIONS'"
                        [iconName]="'bell'" [badgeCountAlert]="unviewedNotificationTotalCount"
                        [badgeCountAlertLimit]="badgeCountAlertLimit" [menuActionClass]="'rpc-notifications-dropdown'"
                        [animationConfig]="badgeAlertAnimationConfig" (closed)="onNotificationBellClosed()">

                        <notifications *ngIf="rpcMenuIconButtonApi.menuOpened"
                            (notificationSourceNavigated)="onNotificationSourceNavigated($event)"
                            (closed)="rpcMenuIconButtonApi.closeMenu()">
                        </notifications>

                    </rpc-menu-icon-button>

                </div>

                <mat-divider [vertical]="true" class="divider-second">
                </mat-divider>

                <div class="toolbar-user">

                    <ng-container
                        *ngLet="{customerFullName: customerFullName$ | async, customerProfileImage: customerProfileImage$ | async} as customer">

                        <div class="toolbar-user-link" *ngIf="customer.customerFullName != null"
                            [title]="customer.customerFullName" #userRippleTrigger [matMenuTriggerFor]="userMenu">

                            <avatar-initials class="username-avatar" mat-icon-button matRipple
                                [matRippleCentered]="true" [matRippleTrigger]="userRippleTrigger"
                                [userFullName]="customer.customerFullName"
                                [userProfileImageUrl]="customer.customerProfileImage">
                            </avatar-initials>

                        </div>

                    </ng-container>

                    <mat-menu class="rpc-dropdown-menu" #userMenu="matMenu" [overlapTrigger]="false">

                        <div class="rpc-dropdown">

                            <rpc-button class="rpc-dropdown-btn" [title]="'TOOLBAR.TITLES.PROFILE'" [iconName]="'user'"
                                (clicked)="onMyProfileClicked()">
                            </rpc-button>

                            <rpc-button class="rpc-dropdown-btn"
                                *ngIf="canSeeAdditionalProfiles$ | async as canSeeAdditionalProfiles"
                                [title]="'TOOLBAR.TITLES.INVITE_USER'" [iconName]="'invite-user'"
                                (clicked)="onInviteUser()" [disabled]="!canSeeAdditionalProfiles">
                            </rpc-button>

                            <rpc-button class="rpc-dropdown-btn" [title]="'TOOLBAR.TITLES.SETTINGS'" [iconName]="'cog'"
                                (clicked)="onSettingsClicked()">
                            </rpc-button>

                            <rpc-button *ngIf="hasMultipleCollaborationSpaces$ | async" class="rpc-dropdown-btn"
                                [title]="'TOOLBAR.TITLES.CHANGE_AGENT'" [iconName]="'agent-switch'"
                                [routerLinkActive]="'active'" (clicked)="onChangeAgentClicked()">
                            </rpc-button>

                            <rpc-button class="rpc-dropdown-btn" [title]="'TOOLBAR.TITLES.LOGOUT'"
                                [iconName]="'sign-out-square-fill'" (clicked)="onLogoutClicked()">
                            </rpc-button>

                        </div>

                    </mat-menu>

                </div>

            </div>

        </ng-container>

    </div>
</mat-toolbar>