<progress-bar class="progress-bar">
</progress-bar>

<div class="refresher">

    <div class="loading-bar">
    </div>
    <div class="loading-bar">
    </div>
    <div class="loading-bar">
    </div>
    <div class="loading-bar">
    </div>

</div>

<div scrollToTopTarget class="global" id="main" [class.global--toolbar]="!configuration.layout.toolbar.hidden">

    <toolbar class="global-toolbar" *ngIf="!configuration.layout.toolbar.hidden">
    </toolbar>

    <div class="global-content" [class.global-content--without-footer]="configuration.layout.footer.hidden && isHybrid"
        cdkScrollable scrollToTopContainer [scrollToTopOffset]="250">

        <!--
            *ngIf="true" hack is required here for router-outlet to work
            correctly. Otherwise, it won't register the changes on the
            layout and won't update the view.
        -->
        <router-outlet *ngIf="true">
        </router-outlet>

        <div class="global-content-print">View is not supported by the Printed Version</div>

        <footer class="global-footer" *ngIf="!configuration.layout.footer.hidden && !isHybrid">
        </footer>

    </div>

    <navigation-panel class="global-bottombar" *ngIf="isUserAuthorized">
    </navigation-panel>

    <custom-spinner class="mp-splash-wrap" *ngIf="!configuration.layout.spinner.hidden" [showImage]="false"
        [class]="configuration.layout.spinner.hidden ? '' : 'mobile-spinner--active'">
    </custom-spinner>

</div>

<core-sidebar class="mobile-menu" *ngIf="isUserAuthorized" name="mobileMenu" [position]="'left'">

    <side-menu>
    </side-menu>

</core-sidebar>