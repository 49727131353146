<div class="rpc-multi-selection" *ngIf="listingIds != null"
    [class.rpc-multi-selection--checked]="selectedListingIds.length > 0">

    <mat-checkbox class="rpc-multi-selection-checkbox" [checked]="allListingsSelected"
        [disabled]="listingIds.length === 0" [indeterminate]="someListingsSelected"
        (change)="onSelectionChanged($event.checked)">
        {{ selectedListingIds.length !== 0
        ? ('MULTI_SELECTION_MENU.TITLES.LISTINGS_SELECTED' | translate: {count: selectedListingIds.length})
        : 'MULTI_SELECTION_MENU.TITLES.SELECT_ALL' | translate }}
    </mat-checkbox>

    <ng-container *ngIf="selectedListingIds.length > 0">

        <ng-container *ngTemplateOutlet="selectionSubMenu">
        </ng-container>

    </ng-container>

</div>